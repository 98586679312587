<script setup>
    import { ref } from 'vue'
    import { useAuthStore } from "@/store/auth"
    import { useToast } from "primevue/usetoast"
    import router from "@/router"

    const { version } = require('../../package.json')

    const authStore = useAuthStore()
    const toast = useToast()

    const email = ref('owner@chill.chill')
    const password = ref('password')

    const loading = ref(false)

    const signIn = (email, password) => {
        loading.value = true

        authStore.login({ email, password }).then(({ data }) => {
            if (data.error) {
                toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
                loading.value = false
                return
            }

            const accessToken = data.access_token
            authStore.setToken({ accessToken })

            toast.add({severity:'success', summary: 'Success', detail: 'Login Successful.', life: 3000})

            router.replace('dashboard')
        
        }).catch((e) => {
            toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        })
    }
</script>
    
<template>
    <Toast />

    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img src="/chillpainai/logo.png" alt="Chillpainai logo" class="mb-5">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <img src="/chillpainai/avatar.png" alt="Image" height="80" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome</div>
                        <span class="text-600 font-medium">Sign in to continue</span>
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="Email" style="padding:1rem;" />
                
                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <Password id="password1" v-model="password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>

                        <Button
                            class="w-full p-3 text-xl" 
                            :disabled="loading"
                            label="Sign In" 
                            :loading="loading"
                            @click="signIn(email, password)"
                        />

                        <div class="text-center mt-5">Version {{ version }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>